<template>
  <div>
    <a-card title="调拨单据">
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >
      <a-spin :spinning="loading">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-row>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="number" label="单据编号">
                <a-input v-model="form.number" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="out_warehouse" label="出库仓库">
                <a-select v-model="form.out_warehouse" style="width: 100%" @change="onChangeWarehouse(0)">
                  <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="out_area" label="出库库区">
                <AreaSelect v-model="form.out_area" :warehouse="form.out_warehouse" :name="form.out_area_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="in_warehouse" label="入库仓库">
                <a-select v-model="form.in_warehouse" style="width: 100%" @change="onChangeWarehouse(1)">
                  <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="in_area" label="入库库区">
                <AreaSelect v-model="form.in_area" :warehouse="form.in_warehouse" :name="form.in_area_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="client" label="客户">
                <a-select v-model="form.client" style="width: 100%">
                  <a-select-option v-for="item in clientItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 320px">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider></a-divider>
        <div>
          <a-row :gutter="16">
            <a-col :span="24" :md="8" :xl="6" style="width: 256px">
              <a-input v-model="materialNumber" placeholder="请扫描产品编号" @keyup.enter.native="scanMaterial" allowClear />
            </a-col>
            <a-space>
              <a-button type="primary" @click="openBatchModal">选择产品</a-button>
            </a-space>
          </a-row>

          <div style="margin-top: 16px">
            <a-table
              rowKey="id"
              size="small"
              :loading="tableLoading"
              :columns="columns"
              :data-source="goodsData"
              :pagination="false"
            >
              <div slot="transfer_quantity" slot-scope="value, item, index">
                <div v-if="item.isTotal">{{ item.transfer_quantity }}</div>
                <a-input-number v-else v-model="item.transfer_quantity" :min="0" size="small"></a-input-number>
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group v-if="!item.isTotal" size="small">
                  <a-button type="danger" @click="removeMaterial(index)">删除</a-button>
                </a-button-group>
              </div>
            </a-table>
          </div>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="loading">创建</a-button>
        </a-popconfirm>
      </div>
    </a-card>
    <!-- <material-options v-model="materialModalVisible" @select="onSelectMaterial"></material-options> -->
    <!-- <inventory-select-modal v-model="materialModalVisible" :warehouse="form.out_warehouse" @select="onSelectMaterial">
    </inventory-select-modal> -->
    <batch-modal v-model="batchModalVisible" :warehouse="form.out_warehouse" :client="form.client" @select="onSelectMaterial">
    </batch-modal>
  </div>
</template>

<script>
import { warehouseOption, clientOption } from "@/api/option";
import { getTransferNumber } from "@/api/data";
import {
  stockTransferOrderCreate,
  transferMaterials,
  stockTransferOrderRetrieve,
  stockTransferOrderMaterials,
} from "@/api/stockTransfer";
import { columns } from "./columns";
import NP from "number-precision";
import { rules } from "./rules";
import moment from "moment";

export default {
  components: {
    BatchModal: () => import("@/components/BatchSelectModal/index"),
    AreaSelect: () => import("@/components/AreaSelect/"),
  },
  data() {
    return {
      rules,
      moment,
      columns,
      loading: false,
      tableLoading: false,
      materialModalVisible: false,
      form: {},
      stockTransferMaterialItems: [],
      warehouseItems: [],
      inventoryModalVisible: false,
      batchModalVisible: false,
      inventoryItem: {},
      materialNumber: "",
      clientItems: [],
    };
  },
  computed: {
    goodsData() {
      // 统计合计
      let transferQuantity = 0;
      for (let item of this.stockTransferMaterialItems) {
        transferQuantity = NP.plus(transferQuantity, item.transfer_quantity);
      }
      return [
        ...this.stockTransferMaterialItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          transfer_quantity: transferQuantity,
        },
      ];
    },
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    show() {
      this.initData();
    },
    initData() {
      this.resetForm();

      const stockTransferOrder = this.$route.query.id;
      if (stockTransferOrder) {
        stockTransferOrderRetrieve({ id: stockTransferOrder }).then((data) => {
          this.form = data;

          getTransferNumber().then((data) => {
            this.form.number = data.number;
          });
        });

        stockTransferOrderMaterials({ id: stockTransferOrder }).then((data) => {
          const materialItems = [];
          for (let item of data) {
            materialItems.push({
              ...item,
              transfer_quantity: item.total_quantity,
            });
          }
          this.stockTransferMaterialItems = materialItems;
        });
      } else {
        getTransferNumber().then((data) => {
          this.form.number = data.number;
        });
      }

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
      clientOption({ page_size: 999999 }).then((data) => {
        this.clientItems = data.results;
      });
    },
    onSelectMaterial(item) {
      let index = this.stockTransferMaterialItems.findIndex((_item) => _item.id == item.id);
      if (index != -1) {
        this.$message.warn("产品已存在");
        return;
      }

      this.stockTransferMaterialItems = this.$functions.insertItem(this.stockTransferMaterialItems, {
        id: item.id,
        batch: item.id,
        material: item.material,
        material_name: item.material_name,
        material_english_name: item.material_english_name,
        material_number: item.material_number,
        batch_number: item.number,
        inventory_quantity: item.remain_quantity,
        transfer_quantity: 0,
      });
    },
    onChangeWarehouse(type) {
      if (type == 0) {
        let { client, ...res } = this.form;
        let newObject = {};
        newObject = res;
        this.form = newObject;
      }
      this.stockTransferMaterialItems = [];
    },
    onCloseInventoryModal(items) {
      let transfer_quantity = 0;
      for (let item of items) {
        transfer_quantity = NP.plus(transfer_quantity, item.total_quantity);
      }
      this.inventoryItem.shelve_record_items = items;
      this.inventoryItem.transfer_quantity = transfer_quantity;
    },
    removeMaterial(index) {
      this.stockTransferMaterialItems.splice(index, 1);
    },
    openInventoryModal() {
      if (!this.form.out_warehouse || !this.form.in_warehouse) {
        this.$message.warn("请选择仓库");
        return;
      }
      this.materialModalVisible = true;
    },
    openBatchModal() {
      if (!this.form.out_warehouse) {
        this.$message.warn("未选择出库仓库");
        return;
      }

      if (!this.form.client) {
        this.$message.warn("未选择客户");
        return;
      }

      this.batchModalVisible = true;
    },
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.stockTransferMaterialItems.length == 0) {
            this.$message.warn("未添加产品");
            return;
          }
          // 筛选调拨产品
          let stock_transfer_material_items = [];
          for (let item of this.stockTransferMaterialItems) {
            stock_transfer_material_items.push({
              batch: item.batch,
              total_quantity: item.transfer_quantity,
            });
            // if (item.shelve_record_items) {
            //   for (let _item of item.shelve_record_items) {
            //     if (_item.total_quantity > 0) {
            //       if (!_item.in_location) {
            //         this.$message.warn(`产品[${item.material_name}] 请选择入库库位`);
            //         return
            //       }

            //       if (!_item.in_batch_number || _item.in_batch_number == '') {
            //         this.$message.warn(`产品[${item.material_name}] 请输入入库批次`);
            //         return
            //       }

            //       stock_transfer_material_items.push({
            //         out_shelve_record: _item.id,
            //         in_location: _item.in_location,
            //         total_quantity: _item.total_quantity,
            //         in_batch_number: _item.in_batch_number,
            //       });
            //     }
            //   }
            // }
          }

          // if (stock_transfer_material_items.length == 0) {
          //   this.$message.warn('没有调拨产品');
          //   return
          // }

          let formData = { ...this.form, stock_transfer_material_items };
          this.loading = true;
          console.log(formData);
          stockTransferOrderCreate(formData)
            .then((data) => {
              this.$message.success("创建成功");
              this.$router.go(-1);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    resetForm() {
      this.form = {};
      this.stockTransferMaterialItems = [];
    },
    scanMaterial() {
      if (!this.form.out_warehouse || !this.form.in_warehouse) {
        this.$message.warn("请选择仓库");
        return;
      }
      if (!this.materialNumber) {
        this.$message.warning("请扫描产品编号！");
        return false;
      }
      this.tableLoading = true;
      transferMaterials({ number: this.materialNumber })
        .then((data) => {
          if (!data.results.length) {
            this.$message.warning("没有找到相关产品，请确认扫描的产品编号！");
            return false;
          }
          let item = data.results[0];
          let index = this.stockTransferMaterialItems.findIndex((_item) => _item.id == item.id);
          if (index != -1) {
            this.stockTransferMaterialItems = this.$functions.replaceItem(this.stockTransferMaterialItems, {
              id: item.id,
              material: item.id,
              material_name: item.name,
              material_number: item.number,
              transfer_quantity: this.stockTransferMaterialItems[index].transfer_quantity + 1,
              unit: item.unit,
            });
          } else {
            this.stockTransferMaterialItems = this.$functions.insertItem(this.stockTransferMaterialItems, {
              id: item.id,
              material: item.id,
              material_name: item.name,
              material_number: item.number,
              transfer_quantity: 1,
              unit: item.unit,
            });
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
  watch: {
    "form.out_warehouse": {
      handler(newValue, oldValue) {
        if (newValue) {
          clientOption({ page_size: 999999, warehouse: newValue }).then((data) => {
            this.clientItems = data.results;
          });
        }
      },
      // immediate: true,
      deep: true,
    },
  },
};
</script>
