export const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    customRender: (value, item, index) => {
      return item.isTotal ? '合计' : (index + 1)
    },
  },
  {
    title: '产品编号',
    dataIndex: 'material_number',
    key: 'material_number',
  },
  {
    title: '产品名称',
    dataIndex: 'material_name',
    key: 'material_name',
  },
  {
    title: '英文名称',
    dataIndex: 'material_english_name',
    key: 'material_english_name',
  },
  {
    title: '批次编号',
    dataIndex: 'batch_number',
    key: 'batch_number',
  },
  {
    title: '当前库存',
    dataIndex: 'inventory_quantity',
    key: 'inventory_quantity',
  },
  {
    title: '调拨数量',
    key: 'transfer_quantity',
    dataIndex: 'transfer_quantity',
    scopedSlots: { customRender: 'transfer_quantity' },
  },
  {
    title: '单位',
    dataIndex: 'unit',
    key: 'unit',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]