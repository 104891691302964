export const rules = {
  number: [
    { required: true, message: '请输入单据编号', trigger: 'change' },
    { max: 32, message: '超出最大长度 (32)', trigger: 'change' },
  ],
  out_warehouse: [
    { required: true, message: '请选择出库仓库', trigger: 'change' },
  ],
  in_warehouse: [
    { required: true, message: '请选择入库仓库', trigger: 'change' },
  ],
  client: [
    { required: true, message: '请选择客户', trigger: 'change' },
  ],
  remark: [
    { max: 256, message: '超出最大长度 (256)', trigger: 'change' },
  ],
}